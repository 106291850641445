<template>
  <!-- 
    画面: 年齢区分編集テーブル
    用途: 年齢区分を編集する
   -->
  <div>

    <!-- #region Table -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("ageTypeEditPage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody>

            <table class="edge-type-table table table-sm table-striped table-bordered ">
              <!-- #region table header section  -->
              <thead class="bg-light text-body text-center">
                <tr>
                  <th width="70">有効</th>
                  <th width="200">年齢</th>
                  <th width="40">食事</th>
                  <th width="40">布団</th>
                  <th width="">名称</th>
                  <th width="120">入湯税</th>
                  <th width="330">価格帯</th>
                  <th width="180">あすなろ年齢区分</th>
                  <th width="70">表示</th>
                  <th width="70">大人人数カウント</th>
                  <th width="110">料金補正</th>
                  <th width="100">値</th>
                </tr>
              </thead>
              <!-- #endregion table header section  -->
              <Draggable
                class="table-row-drag"
                v-model="tableItems"
                @change="onSortEnd"
                v-bind="dragOptions"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                  tag="tbody"
                >
                  <tr
                    v-for="item in tableItems"
                    :key="item.name"
                  >

                    <!-- #region 有効 -->
                    <td class="text-center">
                      <CSwitch
                        class="mt-2 swc sw-1"
                        color="info"
                        variant="opposite"
                        :labelOn='$t("common.valid")'
                        :labelOff='$t("common.invalid")'
                        :checked="item.status | masterStatus"
                        @update:checked="onStatusChanged(item)"
                        size="sm"
                      />
                    </td>
                    <!-- #endregion 有効 -->

                    <!-- #region 年齢 -->
                    <td>
                      <ul class="ul-line-block">
                        <li>
                          <CInput
                            class="w-2"
                            v-model.number="item.min"
                            placeholder="min"
                            type="number"
                            append="歳"
                            size="sm"
                          />
                        </li>
                        <li>~
                        </li>
                        <li>
                          <CInput
                            class="w-2"
                            type="number"
                            v-model.number="item.max"
                            placeholder="max"
                            append="歳"
                            size="sm"
                          />
                        </li>
                      </ul>
                    </td>
                    <!-- #endregion 年齢 -->

                    <!-- #region 食事 -->
                    <td class="text-center">
                      <template v-if="item.food">
                        <i class="icon cil-restaurant mt-2 d-block"></i>
                      </template>
                    </td>
                    <!-- #endregion 食事 -->

                    <!-- #region 布団 -->
                    <td class="text-center">
                      <template v-if="item.bed">
                        <i class="icon cil-bed mt-2 d-block"></i>
                      </template>
                    </td>
                    <!-- #endregion 布団 -->

                    <!-- #region 名称 -->
                    <td>
                      <CInput
                        v-model="item.name"
                        size="sm"
                      />
                    </td>
                    <!-- #endregion 名称 -->

                    <!-- #region 入湯税 -->
                    <td>
                      <CRow
                        v-for="bathTax in item.bathTaxes"
                        :key="bathTax.id"
                        class="m-0 p-0  border-bottom "
                      >
                        <CCol class="">
                          <CInput
                            class="w-3 mx-auto"
                            type="number"
                            v-model.number="bathTax.tax"
                            append="円"
                            size="sm"
                          />
                        </CCol>
                      </CRow>
                    </td>
                    <td>
                      <CRow
                        v-for="bathTax in item.bathTaxes"
                        :key="bathTax.id"
                        class="m-0 p-0 border-bottom"
                      >
                        <CCol>
                          <ul class="ul-line-block d-table mx-auto">
                            <li>
                              <CInput
                                class="w-3"
                                v-model.number="bathTax.min"
                                placeholder="min"
                                type="number"
                                append="円"
                                size="sm"
                              >
                              </CInput>
                            </li>
                            <li>~</li>
                            <li>
                              <CInput
                                class="w-3"
                                v-model.number="bathTax.max"
                                placeholder="max"
                                type="number"
                                append="円"
                                size="sm"
                              >
                              </CInput>
                            </li>
                            <li>
                              <ul class="tax-f-button">
                                <li>
                                  <CButton
                                    v-if="item.bathTaxes.length > 1"
                                    @click="onBathTaxDeleteClicked(item.id, bathTax.id)"
                                    color="danger"
                                    size="sm"
                                    class="py-0"
                                  >
                                    <strong>ー</strong>
                                  </CButton>
                                </li>
                                <li>
                                  <CButton
                                    v-if="viewBathTaxAddButton(bathTax.id, item)"
                                    @click="onBathTaxAddClicked(item.id, item.bathTaxes)"
                                    color="info"
                                    size="sm"
                                    class="px-3 py-0 ml-2"
                                  >
                                    <strong>+</strong>
                                  </CButton>
                                </li>
                              </ul>
                            </li>
                          </ul>

                          <!-- {{item.bathTaxes[item.bathTaxes.length].id}} -->

                        </CCol>
                      </CRow>
                    </td>
                    <!-- #endregion 入湯税 -->

                    <!-- #region あすなろ年齢区分 -->
                    <td>
                      <CSelect
                        :options="asunaroList"
                        :value.sync="item.asunaro"
                        size="sm"
                        disabled
                      />
                    </td>
                    <!-- #endregion あすなろ年齢区分 -->

                    <!-- #region 受入 -->
                    <td class="text-center">
                      <CSwitch
                        class="swc sm-switch-l mt-2"
                        color="info"
                        variant="opposite"
                        :labelOn='$t("common.active")'
                        :labelOff='$t("common.inActive")'
                        :checked.sync="item.isAccept"
                        size="sm"
                      />
                    </td>
                    <!-- #endregion 受入 -->

                    <!-- #region 大人人数カウント -->
                    <td class="text-center">
                      <CSwitch
                        class="mt-2 swc sm-switch-l"
                        color="info"
                        variant="opposite"
                        :labelOn='$t("common.do")'
                        :labelOff='$t("common.doNot")'
                        :checked.sync="item.isAdultCount"
                        :disabled="item.asunaro == 1 || !item.isAccept ? 'disabled' : undefined"
                        size="sm"
                      />
                    </td>
                    <!-- #endregion 大人人数カウント -->

                    <!-- #region 料金補正 -->
                    <td>
                      <CSelect
                        :options="priceCorrectionTypes"
                        :value.sync="item.priceCorrectionType"
                        size="sm"
                        :disabled="item.asunaro == 1 || !item.isAccept ? 'disabled' : undefined"
                      />
                    </td>
                    <!-- #endregion 料金補正 -->

                    <!-- #region 値 -->
                    <td>
                      <CInput
                        class="w-2"
                        v-model.number="item.priceCorrectionValue"
                        placeholder="0"
                        type="number"
                        size="sm"
                        :disabled="item.asunaro == 1 || !item.isAccept ? 'disabled' : undefined"
                      />
                    </td>
                    <!-- #endregion 値 -->

                  </tr>
                </transition-group>
              </Draggable>
            </table>

          </CCardBody>
          <CCardFooter>
            <!-- #region Action field -->
            <template>
              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->
            </template>
            <!-- #endregion Action field -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Table -->

    <!-- #region Modal -->
    <template>

      <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />

      <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />

    </template>
    <!-- #endregion Modal -->

  </div>
</template>

<script>
import axios from "axios";

// import {
//   required,
//   // numeric,
//   // minLength,
//   // maxLength,
//   // helpers,
// } from "vuelidate/lib/validators";

const STATUS = {
  VALID: 100,
  INVALID: 200,
};

export default {
  name: "AgeEditTable",

  components: {},

  props: {},

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      return !this.loading && !this.notFound;
    },

    /** ボタン有効か？ */
    activeStatus() {
      return this.$v.tableItems.$invalid ? "inactive" : "active";
    },

    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: true,
        ghostClass: "ghost",
      };
    },

    /** 宿UniqueID */
    uid() {
      return this.$store.state.uniqueId;
    },
  },

  validations() {
    return {
      tableItems: {
        isValid: {},
        age: {},
        food: {},
        bed: {},
        name: {},
        bathTaxes: {},
        asunaro: {},
      },
    };
  },

  data() {
    return {
      //#region Flag

      loading: false,
      successModal: false,
      errorModal: false,

      //#endregion Flag

      //#region Table

      /** 一覧表データ */
      // tableItems: [],

      // TODO: 以下はレイアウト確認用データのため、API結合後は削除すること
      tableItems: [
        {
          id: "01",
          order: 1,
          status: STATUS.VALID,
          min: 13,
          max: null,
          name: "大人",
          food: true,
          bed: true,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 1,
          isAccept: true,
          isAdultCount: true,
          priceCorrectionType: 0,
          priceCorrectionValue: null,
        },
        {
          id: "02",
          order: 2,
          status: STATUS.VALID,
          min: 10,
          max: 12,
          name: "小学生(高学年)",
          food: true,
          bed: true,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 11,
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "03",
          order: 3,
          status: STATUS.VALID,
          min: 6,
          max: 9,
          name: "小学生(低学年)",
          food: true,
          bed: true,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 12,
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "04",
          order: 4,
          status: STATUS.VALID,
          min: 1,
          max: 5,
          name: "幼児(食事有・布団有)",
          food: true,
          bed: true,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 21,
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "05",
          order: 5,
          status: STATUS.VALID,
          min: 1,
          max: 5,
          name: "幼児(食事有・布団無)",
          food: true,
          bed: false,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 22,
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "06",
          order: 6,
          status: STATUS.VALID,
          min: 1,
          max: 5,
          name: "幼児(食事無・布団有)",
          food: false,
          bed: true,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 23,
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "07",
          order: 7,
          status: STATUS.VALID,
          min: 1,
          max: 5,
          name: "幼児(食事無・布団無)",
          food: false,
          bed: false,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 24,
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "08",
          order: 8,
          status: STATUS.INVALID,
          min: 0,
          max: 0,
          name: "乳児(食事有・布団有)",
          food: true,
          bed: true,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 31,
          isAccept: false,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "09",
          order: 9,
          status: STATUS.INVALID,
          min: 0,
          max: 0,
          name: "乳児(食事有・布団無)",
          food: true,
          bed: false,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 32,
          isAccept: false,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "10",
          order: 10,
          status: STATUS.INVALID,
          min: 0,
          max: 0,
          name: "乳児(食事無・布団有)",
          food: false,
          bed: true,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 33,
          isAccept: false,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
        {
          id: "11",
          order: 11,
          status: STATUS.INVALID,
          min: 0,
          max: 0,
          name: "乳児(食事無・布団無)",
          food: false,
          bed: false,
          bathTaxes: [{ id: 0, tax: 0, min: null, max: null }],
          asunaro: 34,
          isAccept: false,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
        },
      ],

      /** あすなろ年齢区分 */
      // TODO: APIからデータ取得
      asunaroList: [
        { value: 1, label: "大人" },
        { value: 11, label: "小学生(高学年)" },
        { value: 12, label: "小学生(低学年)" },
        { value: 21, label: "幼児(食事有・布団有)" },
        { value: 22, label: "幼児(食事有・布団無)" },
        { value: 23, label: "幼児(食事無・布団有)" },
        { value: 24, label: "幼児(食事無・布団無)" },
        { value: 31, label: "乳児(食事有・布団有)" },
        { value: 32, label: "乳児(食事有・布団無)" },
        { value: 33, label: "乳児(食事無・布団有)" },
        { value: 34, label: "乳児(食事無・布団無)" },
      ],

      /** 料金補正タイプ */
      priceCorrectionTypes: [
        { value: 0, label: "金額指定" },
        { value: 1, label: "パーセント" },
        { value: 2, label: "値引き" },
      ],

      //#endregion Table

      //#region Response data
      //#endregion Response data

      //#region Message

      errorMessage: "",

      //#endregion Message
    };
  },

  methods: {
    //#region Event
    /** draggable items sorting */
    onSortEnd(event) {
      console.log(JSON.stringify(event));
      this.tableItems.forEach((a, i) => {
        this.tableItems[i].dispOrder = i;
      });
    },

    /** 入湯税削除ボタン押下 */
    onBathTaxDeleteClicked(ageTypeId, bathTaxId) {
      for (let a of this.tableItems) {
        // 年齢区分IDと違うならスキップ
        if (a.id != ageTypeId) continue;

        // 入湯税行を削除
        a.bathTaxes.some((v, i) => {
          if (v.id == bathTaxId) a.bathTaxes.splice(i, 1);
        });
      }
    },

    /** 入湯税追加ボタン押下 */
    onBathTaxAddClicked(ageTypeId, bathTaxes) {
      for (let a of this.tableItems) {
        // 年齢区分IDと違うならスキップ
        if (a.id != ageTypeId) continue;

        // 入湯税行を追加
        const nextId = this.getBathTaxRowNextDispOrder(bathTaxes);
        bathTaxes.push(this.createNewBathTaxRow(nextId));
        // TODO: DB登録済みの入湯税IDと重複しないようにするには？ API側で既存データを削除する。
      }
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.tableItems.$invalid ? this.$v.tableItems.$touch() : this.reqPut();
    },

    /** 有効無効切替 */
    onStatusChanged(item) {
      // 有効無効ステータス更新リクエスト
      item.status = this.toggleStatus(item.status);
    },

    //#endregion Event

    //#region Request

    reqGet() {
      const url = `/rest/hotel/${this.uid}/age`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));

          this.response = resp.data;
          this.pourTable(resp.data.ages);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    reqPut() {
      const url = `/rest/hotel/${this.uid}/age`;
      const body = { ages: this.tableItems };

      console.log("put: ");
      console.log(JSON.stringify(body));

      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));

          this.response = resp.data;
          this.pourTable(resp.data.ages);

          this.successModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = "エラーがあります。";
          this.errorModal = true;
        });
    },

    //#endregion Request

    //#region Method

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        const bathTaxes = [];
        if (data.bathTaxes !== null && data.bathTaxes.length > 0) {
          data.bathTaxes.forEach((b) => {
            bathTaxes.push({
              id: b.id,
              tax: b.tax,
              min: b.min,
              max: b.max,
              status: b.status,
              dispOrder: b.dispOrder,
              remark: b.remark,
            });
          });
        } else {
          bathTaxes.push(this.createNewBathTaxRow(1));
        }

        this.tableItems.push({
          id: data.id,
          name: data.name,
          description: data.description,
          min: data.min,
          max: data.max,
          food: data.food,
          bed: data.bed,
          asunaro: data.asunaro,
          status: data.status,
          dispOrder: data.dispOrder,
          remark: data.remark,
          bathTaxes: bathTaxes,
          isAccept: data.isAccept,
          isAdultCount: data.isAdultCount,
          priceCorrectionType: data.priceCorrectionType,
          priceCorrectionValue: data.priceCorrectionValue,
        });
      });

      console.log(JSON.stringify(this.tableItems));
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    toggleStatus(status) {
      return status == STATUS.VALID ? STATUS.INVALID : STATUS.VALID;
    },

    viewBathTaxAddButton(bathTaxId, item) {
      return bathTaxId == item.bathTaxes[item.bathTaxes.length - 1].id;
    },

    createNewBathTaxRow(dispOrder) {
      return {
        id: null,
        tax: 0,
        min: null,
        max: null,
        status: STATUS.INVALID,
        dispOrder: dispOrder,
        remark: "",
      };
    },

    /** 入湯税のNextId取得 */
    getBathTaxRowNextDispOrder(bathTaxes) {
      return bathTaxes.reduce((a, b) => (a > b ? a : b)).dispOrder + 1;
    },

    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>
<style lang="scss">
.bath-min,
.bath-max {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  border: none;
  border-top: 1px solid #d8dbe0;
  &:first-child{
    border-top: none;
  }
}
.tax-f-button li {
  width: 30px;
  display: inline-block;
}
</style>
