<template>
  <!-- 
    画面: 年齢区分設定
    用途: 年齢区分を設定する
   -->
  <div>
    <AgeEditTable />
  </div>
</template>

<script>
import AgeEditTable from "./components/EditTable";

export default {

  name: "AgeTypeEdit",

  components: {
    AgeEditTable,
  },

}
</script>
